import React from "react";
import { Link } from "react-router-dom";

import ImgCategory1 from "./../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 1.png";
import ImgCategory2 from "./../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 2.png";
import ImgCategory3 from "./../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 3.png";
import ImgCategory4 from "./../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 4.png";
import ImgCategory5 from "./../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 5.png";

import ImgTrophy from "./../assets/images/filsoc-awards/awards-trophy.png";
import ImgHeader from "./../assets/images/filsoc-awards/awards-header.svg";
import { useInView } from "react-intersection-observer";
import { AwardsParticles } from "../components/Particles";

import AwardsHeader from "../components/AwardsHeader";
import AwardsFooter from "../components/AwardsFooter";

const FiliFestAwards = () => {
  return (
    <section className="filifest-awards">
      <AwardsHeader />

      <section className="pick-category">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center p-5">
            <h1>Pick Category</h1>
          </div>
          <div className="row categories">
            <div
              className="col-12 col-sm-6 category"
              data-sal="fade"
              data-sal-delay={0}
              data-sal-duration={1000}
            >
              <Link to="best-filsoc-president" className="nav-link">
                <img src={ImgCategory5} className="w-100 hvr-bob" />
              </Link>
            </div>
            <div
              className="col-12 col-sm-6 category"
              data-sal="fade"
              data-sal-delay={0}
              data-sal-duration={1000}
            >
              <Link to="best-event" className="nav-link">
                <img src={ImgCategory1} className="w-100 hvr-bob" />
              </Link>
            </div>
            <div
              className="col-12 col-sm-6 category"
              data-sal="fade"
              data-sal-delay={0}
              data-sal-duration={1000}
            >
              <Link to="peoples-choice-popular-filsoc" className="nav-link">
                <img src={ImgCategory2} className="w-100 hvr-bob" />
              </Link>
            </div>
            <div
              className="col-12 col-sm-6 category"
              data-sal="fade"
              data-sal-delay={0}
              data-sal-duration={1000}
            >
              <Link to="/filifest-community-choice-award" className="nav-link">
                <img src={ImgCategory3} className="w-100 hvr-bob" />
              </Link>
            </div>
            <div
              className="col-12 col-sm-6 category"
              data-sal="fade"
              data-sal-delay={0}
              data-sal-duration={1000}
            >
              <Link to="best-performer-of-the-year" className="nav-link">
                <img src={ImgCategory4} className="w-100 hvr-bob" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <AwardsFooter />
    </section>
  );
};

export default FiliFestAwards;
