import React from "react";
import { useInView } from "react-intersection-observer";
import { AwardsParticles } from "../components/Particles";
import ImgHeader from "./../assets/images/filsoc-awards/awards-header-closed.svg";

const AwardsHeader = () => {
  const [ref, inView] = useInView();

  return (
    <header className="filsoc-awards mx-auto">
      <AwardsParticles />

      <img
        src={ImgHeader}
        className="filsoc-awards__header--img"
        style={{
          transition: "opacity 2000ms, transform 2000ms",
          opacity: inView ? 1 : 0,
          transform: `translateY(${inView ? 0 : -100}px)`,
          transition: "all 2s",
        }}
        ref={ref}
      />

      <div className="row">
        <div
          className="col-6 offset-3 col-sm-2 offset-sm-5"
          data-sal="fade-up"
          data-sal-delay={400}
          data-sal-duration={1500}
        >
          {/* <img src={ImgTrophy} className="w-100" /> */}
        </div>
      </div>
    </header>
  );
};

export default AwardsHeader;
