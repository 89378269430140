import React, { useState } from "react";

const PeoplesChoicePopularFilsoc = (vote) => {
  const options = [
    "Aston University",
    "Bangor University",
    "University of Birmingham",
    "University of Bristol",
    "Brunel University",
    "University of Cambridge",
    "Cardiff Metropolitan University",
    "Cardiff University",
    "Coventry University",
    "De Montfort University",
    "University of Derby",
    "Durham University",
    "University of East Anglia",
    "University of Edinburgh",
    "University of Essex",
    "University of Exeter",
    "University of Greenwich",
    "University of Hertfordshire",
    "Imperial College London",
    "University of Kent",
    "Kingston University",
    "Lancaster University",
    "University of Leeds",
    "University of Manchester",
    "Manchester Metropolitan University",
    "Newcastle University",
    "Northumbria University",
    "University of Nottingham",
    "Nottingham Trent University",
    "University of Oxford",
    "Oxford Brookes University",
    "University of Portsmouth",
    "Queen's University Belfast",
    "University of Reading",
    "University of Sheffield",
    "University of Southampton",
    "University of St Andrews",
    "University of Strathclyde",
    "University of Surrey",
    "University of Sussex",
    "Swansea University",
    "University of the Arts London",
    "University of Warwick, Coventry",
    "University of the West of England",
    "King's College London",
    "London School of Economics and Political Science (LSE)",
    "Queen Mary, University of London",
    "St George's, University of London",
    "University College London (UCL)",
    "Bournemouth University",
    "Loughborough University",
    "University of Lincoln",
    "Birmingham City University",
  ];
  const [selected, setSelected] = useState(options[0]["name"]);

  return (
    <section className="best-event">
      <fieldset
        value={selected}
        onChange={(event) => setSelected(event.target.value)}
        className="filter-toolbar"
        role="group"
        aria-label="..."
        disabled={vote.vote ? true : false}
      >
        <div className="row">
          {options.sort().map((item, i) => (
            <div
              className="col-sm-3 col-12 p-4 d-flex align-items-center justify-content-center flex-column"
              key={i}
            >
              <label for={item}>
                {/* <img src={item.img} alt={item} className="w-100" /> */}

                <h3 className="h5 d-flex align-items-center justify-content-center text-center">
                  <input
                    type="radio"
                    value={item}
                    name="options"
                    id={item}
                    defaultChecked={i === 0 ? true : false}
                  />

                  <span className="p-2">{item}</span>
                </h3>
              </label>
            </div>
          ))}
        </div>
      </fieldset>

      {vote.vote ? (
        <h3 className="py-5">Voted for {vote.vote}</h3>
      ) : (
        <h3 className="py-5">
          Voting For: <br /> {selected}
        </h3>
      )}
    </section>
  );
};

export default PeoplesChoicePopularFilsoc;
