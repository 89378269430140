import React, { useState } from "react";

const BestEvent = (vote) => {
  const options = [
    {
      name: "@derbyfilsoc — Karaoke & Games",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/@derbyfilsoc — Karaoke _ Games.jpg"),
    },
    {
      name: "@ku.filisoc @sgulfilisoc @qmulfilisoc @filsocuh @kclfilipinosociety @brunelfilisoc - Valentines Ball",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/@ku.filisoc @sgulfilisoc @qmulfilisoc @filsocuh @kclfilipinosociety - Valentines Ball.jpg"),
    },
    {
      name: "@northumbriafilsoc @nclfilsoc — Boodle Fight",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/@northumbriafilsoc @nclfilsoc — Boodle Fight.jpg"),
    },
    {
      name: "@ueafilsoc — Valentines Ball",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/@ueafilsoc — Valentine_s Ball.jpg"),
    },
    {
      name: "@ukc.filsoc — Squid Game Night",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/@ukc.filsoc — Squid Game Night.jpg"),
    },
    {
      name: "@uobfilsoc @dmufilipinosociety @derbyfilsoc @warwick.filsoc @cufilsoc @bcufilsoc — Inter-Soc Sports Fest",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/@uobfilsoc @dmufilipinosociety @derbyfilsoc @warwick.filsoc @cufilsoc @bcufilsoc — Inter-Soc Sports Fest.jpg"),
    },
    {
      name: "@warwick.filsoc @cufilsoc @uobfilsoc — Games Night",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/@warwick.filsoc @cufilsoc @uobfilsoc — Games Night.jpg"),
    },
    {
      name: "@bristol_filsoc @filisoc_cu @uwefilsoc — Jollibee Collab",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/_ @bristol_filsoc @@filisoc_cu @uwefilsoc_ — Jollibee Collab.jpg"),
    },
    {
      name: "@brookesfilsoc — Games Night",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/_ @brookesfilsoc — Games Night.jpg"),
    },
    {
      name: "@northumbriafilsoc @nclfilsoc — Valentines Virtual Event",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/_ @northumbriafilsoc @nclfilsoc — Valentines Virtual Event.jpg"),
    },
    {
      name: "@uorfilsoc — Acoustic Night",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/_ @uorfilsoc — Acoustic Night.jpg"),
    },
    {
      name: "@filisoc_cu — Potluck",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/_@filisoc_cu — Potluck.jpg"),
    },
    {
      name: "@warwick.filsoc @uobfilsoc @cufilsoc @bcufilsoc @dmufilipinosociety @mcrfilsoc @derbyfilsoc - FilSoc Friday",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/_FilSocFriday Warwick, MCR, UOB, DMU, BCU, COVENTRY.PNG"),
    },
    {
      name: "@lancsfilsoc - Karaoke Night",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/_Karaoke Lancaster.PNG"),
    },
    {
      name: "@uclfilipinosociety @kclfilipinosociety @iclfilipinosoc @lsesufilsoc @ual.filsoc - Valentines Social",
      img: require("../../assets/images/filsoc-awards/categories/Best Event — IG/_Valentines Social - UCL, KCL, ICL, LSE, UAL.png"),
    },
  ];
  const [selected, setSelected] = useState(options[0]["name"]);

  return (
    <section className="best-event">
      <fieldset
        value={selected}
        onChange={(event) => setSelected(event.target.value)}
        className="filter-toolbar"
        role="group"
        aria-label="..."
        disabled={vote.vote ? true : false}
      >
        <div className="row">
          {options.map((item, i) => (
            <div
              className="col-sm-6 col-12 p-4 d-flex align-items-center justify-content-center flex-column"
              key={i}
            >
              <label for={item.name}>
                <img src={item.img} alt={item.name} className="w-100" />

                <h3 className="h5 d-flex align-items-center justify-content-center text-center">
                  <input
                    type="radio"
                    value={item.name}
                    name="options"
                    id={item.name}
                    defaultChecked={i === 0 ? true : false}
                  />

                  <span className="p-5">{item.name}</span>
                </h3>
              </label>
            </div>
          ))}
        </div>
      </fieldset>

      {vote.vote ? (
        <h3 className="py-5">Voted for {vote.vote}</h3>
      ) : (
        <h3 className="py-5">
          Voting For: <br /> {selected}
        </h3>
      )}
    </section>
  );
};

export default BestEvent;
