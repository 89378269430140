import React from "react";
import { Link } from "react-router-dom";

const CategorySelector = () => {
  return (
    <section className="category-selector">
      <ul>
        <li>
          <a href="/best-filsoc-president">
            <h3>Best Filsoc President</h3>
          </a>
        </li>
        <li>
          <a href="/best-event">
            <h3>Best Event</h3>
          </a>
        </li>
        <li>
          <a href="/peoples-choice-popular-filsoc">
            <h3>Peoples Choice Popular Filsoc</h3>
          </a>
        </li>
        <li>
          <a href="/filifest-community-choice-award">
            <h3>Filfest community choice award</h3>
          </a>
        </li>
        <li>
          <a href="/best-performer-of-the-year">
            <h3>Best performer of the year</h3>
          </a>
        </li>
      </ul>
    </section>
  );
};

export default CategorySelector;
