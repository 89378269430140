import React from "react";
import logo from "../assets/images/logo-main.png";
import imgClouds from "../assets/images/art-icons/clouds3.png";
import imgHappyChildren from "../assets/images/art-icons/happychildren.png";
import imgVintaWaves from "../assets/images/art-icons/vintawaves.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section
      className="footer bg-white mx-auto"
      data-sal="fade"
      data-sal-duration={2000}
    >
      <div className="row" id="footer-logo">
        <div className="col-6 col-md-2 align-self-start pl-4 pt-3">
          <a
            className="navbar-brand js-scroll-trigger "
            href="https://www.filifest.co.uk"
          >
            <img src={logo} className="w-100" alt="" />
          </a>
        </div>
      </div>

      <ul class="footer__list">
        <li class="footer__list-item">
          <a
            href="https://www.filifest.co.uk/about"
            fade
            className="footer__list-item--about"
          >
            <h2>About</h2>
          </a>
        </li>

        <li class="footer__list-item">
          <a
            href="https://www.filifest.co.uk/community"
            fade
            className="footer__list-item--community"
          >
            <h2>Community</h2>
          </a>
        </li>
        <li class="footer__list-item">
          <a
            href="https://www.filifest.co.uk/blog"
            fade
            className="footer__list-item--blog"
          >
            <h2>Blog</h2>
          </a>
        </li>
        <li class="footer__list-item">
          <a
            href="https://www.filifest.co.uk/shop"
            fade
            className="footer__list-item--shop"
          >
            <h2>Shop</h2>
          </a>
        </li>
        <li class="footer__list-item">
          <a
            href="https://www.filifest.co.uk/events"
            fade
            className="footer__list-item--events"
          >
            <h2>Events</h2>
          </a>
        </li>
        <li class="footer__list-item">
          <a
            href="https://www.filifest.co.uk/contact"
            fade
            className="footer__list-item--contact"
          >
            <h2>Contact</h2>
          </a>
        </li>

        <li class="footer__list-item">
          <Link to="/" fade className="footer__list-item--community">
            <h2>Filifest Awards</h2>
          </Link>
        </li>
      </ul>

      <div className="row">
        <p className="d-flex align-items-end justify-content-center">
          <a href="https://www.filifest.co.uk/faq" fade className="px-3">
            {" "}
            FAQs{" "}
          </a>{" "}
          <a href="https://www.filifest.co.uk/privacy" fade className="px-3">
            {" "}
            Privacy Policy
          </a>{" "}
        </p>
      </div>

      <div className="row footer__socials">
        <div className="col-12 text-center">
          <a href="mailto:filifestuk@gmail.com">
            <FontAwesomeIcon icon={["fas", "envelope"]} className="m-1" />
          </a>
          <a href="https://www.facebook.com/filifestuk/" target="_blank">
            <FontAwesomeIcon icon={["fab", "facebook"]} className="m-1" />
          </a>
          <a href="https://www.instagram.com/filifest/" target="_blank">
            <FontAwesomeIcon icon={["fab", "instagram"]} className="m-1" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCbc2gJGBtp9uSxkrRDG_QVw"
            target="_blank"
          >
            <FontAwesomeIcon icon={["fab", "youtube"]} className="m-1" />
          </a>
        </div>
      </div>

      <p
        id="copyright"
        className="align-items-end justify-content-center mt-auto text-center"
      >
        <small>© FiliFest 2022. All rights reserved.</small>
      </p>

      <img
        src={imgHappyChildren}
        id="footer-yellow"
        className="footer-bg-img"
      />
      <img src={imgClouds} id="footer-blue" className="footer-bg-img" />
      <img src={imgVintaWaves} id="footer-red" className="footer-bg-img" />
    </section>
  );
};

export default Footer;
