import React, { useState } from "react";

const BestFilsocPresident = (vote) => {
  const options = [
    {
      name: "@bristol_filsoc — Charles",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@bristol_filsoc — Charles.jpg"),
    },
    {
      name: "@brookesfilsoc — Mary",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@brookesfilsoc — Mary.jpg"),
    },
    {
      name: "@brunelfilisoc — Jezelle",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@brunelfilisoc — Jezelle.jpg"),
    },
    {
      name: "@derbyfilsoc — Karlos",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@derbyfilsoc — Karlos.jpg"),
    },
    {
      name: "@filisoc_cu — Alyana",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@filisoc_cu — Alyana.jpg"),
    },
    {
      name: "@filsocsoton — Ella",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@filsoc_soton — Ella.jpg"),
    },
    {
      name: "@liverpoolfilsoc — Jasmin",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@liverpoolfilsoc — Jasmin.jpg"),
    },
    {
      name: "@mcrfilsoc — Jahn",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@mcrfilsoc — Jahn.jpg"),
    },
    {
      name: "@nclfilsoc - Jedd",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@nclfilsoc - Jedd.jpg"),
    },
    {
      name: "@northumbriafilsoc — Gabriel",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@northumbriafilsoc — Gabriel.jpg"),
    },
    {
      name: "@qmulfilisoc — Julius",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@qmulfilisoc — Julius.jpg"),
    },
    {
      name: "@sgulfilisoc — Lien",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@sgulfilisoc — Lien.jpg"),
    },
    {
      name: "@ueafilsoc — Mica",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@ueafilsoc — Mica.jpg"),
    },
    {
      name: "@lufilsoc — JV",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@ufilsoc — JV.jpg"),
    },
    {
      name: "@ukc.filsoc - Gio",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@ukc.filsoc - Gio.jpg"),
    },
    {
      name: "@uobfilsoc — Joie",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@uobfilsoc — Joie.jpg"),
    },
    {
      name: "@uopfilipinosociety — Nicole",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@uopfilipinosociety — Nicole.jpg"),
    },
    {
      name: "@uorfilsoc — Carlo",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@uorfilsoc — Carlo.jpg"),
    },
    {
      name: "@warwick.filsoc — Angelina",
      img: require("../../assets/images/filsoc-awards/categories/Best FilSoc Presidents — IG/@warwick.filsoc — Angelina.jpg"),
    },
  ];
  const [selected, setSelected] = useState(options[0]["name"]);

  return (
    <section className="best-filsoc-president">
      <fieldset
        value={selected}
        onChange={(event) => setSelected(event.target.value)}
        className="filter-toolbar"
        role="group"
        aria-label="..."
        disabled={vote.vote ? true : false}
      >
        <div className="row">
          {options.map((item, i) => (
            <div
              className="col-sm-6 col-12 p-4 d-flex align-items-center justify-content-center flex-column"
              key={i}
            >
              <label for={item.name}>
                <img src={item.img} alt={item.name} className="w-100" />

                <h3 className="h5 d-flex align-items-center justify-content-center text-center">
                  <input
                    type="radio"
                    value={item.name}
                    name="options"
                    id={item.name}
                    defaultChecked={i === 0 ? true : false}
                  />

                  <span className="p-5">{item.name}</span>
                </h3>
              </label>
            </div>
          ))}
        </div>
      </fieldset>

      {vote.vote ? (
        <h3 className="py-5">Voted for {vote.vote}</h3>
      ) : (
        <h3 className="py-5">
          Voting For: <br /> {selected}
        </h3>
      )}
    </section>
  );
};

export default BestFilsocPresident;
