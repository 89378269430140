import React, { useState } from "react";

const BestPerformerOfTheYear = (vote) => {
  const options = [
    {
      name: "Alexis",
      img: require("../../assets/images/filsoc-awards/categories/Best Performances — IG/Alexis.png"),
      link: "https://www.instagram.com/p/CWacjJaIi-H/",
    },
    {
      name: "Gabriel",
      img: require("../../assets/images/filsoc-awards/categories/Best Performances — IG/Gabriel Matorres.jpg"),
      link: "https://youtu.be/hejzX2UY0Dw?t=122",
    },
    {
      name: "Jessie & Tak",
      img: require("../../assets/images/filsoc-awards/categories/Best Performances — IG/Jessie _ Tak.png"),
      link: "https://youtu.be/hejzX2UY0Dw?t=108",
    },
    {
      name: "Maria",
      img: require("../../assets/images/filsoc-awards/categories/Best Performances — IG/Maria Maxene Marcos Gerella.png"),
      link: "https://www.youtube.com/watch?v=1pI_8NVetdQ",
    },
    {
      name: "Melody",
      img: require("../../assets/images/filsoc-awards/categories/Best Performances — IG/Melody Grace.png"),
      link: "https://www.instagram.com/p/CWIaytPoQI-/?utm_source=ig_web_button_share_sheet",
    },
    {
      name: "Sherwin",
      img: require("../../assets/images/filsoc-awards/categories/Best Performances — IG/Sherwin Panganiban.png"),
      link: "https://www.instagram.com/p/CWIaytPoQI-/",
    },
  ];
  const [selected, setSelected] = useState(options[0]["name"]);

  return (
    <section className="best-performer-of-the-year">
      <fieldset
        value={selected}
        onChange={(event) => setSelected(event.target.value)}
        className="filter-toolbar"
        role="group"
        aria-label="..."
        disabled={vote.vote ? true : false}
      >
        <div className="row">
          {options.map((item, i) => (
            <div
              className="col-sm-6 col-12 p-4 d-flex align-items-center justify-content-center flex-column"
              key={i}
            >
              <label for={item.name}>
                <img src={item.img} alt={item.name} className="w-100" />

                <h3 className="h5 d-flex align-items-center justify-content-center text-center">
                  <input
                    type="radio"
                    value={item.name}
                    name="options"
                    id={item.name}
                    defaultChecked={i === 0 ? true : false}
                  />

                  <span className="p-5">{item.name}</span>
                </h3>
              </label>
              <h3 className="h5">
                <a href={item.link} target="_blank" className="btn btn-dark">
                  Watch
                </a>
              </h3>
            </div>
          ))}
        </div>
      </fieldset>

      {vote.vote ? (
        <h3 className="py-5">Voted for {vote.vote}</h3>
      ) : (
        <h3 className="py-5">
          Voting For: <br /> {selected}
        </h3>
      )}
    </section>
  );
};

export default BestPerformerOfTheYear;
