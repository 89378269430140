import React from "react";
import { Link } from "react-router-dom";

import ImgBiniBini from "../assets/images/filsoc-awards/sponsors/Binibini LOGO.png";
import ImgRapsa from "../assets/images/filsoc-awards/sponsors/cheft_octopus_sq.gif";
import ImgKhouse from "../assets/images/filsoc-awards/sponsors/K-House-Gold.png";

import ImgKKOrange from "../assets/images/filsoc-awards/sponsors/kk-orange.png";
import ImgSarap from "../assets/images/filsoc-awards/sponsors/sarap-filipino-bistro-logo-13585.jpg";

const AwardsFooter = () => {
  return (
    <section className="awards-footer">
      <section
        className="filsoc-awards-footer bg-filsoc-awards mt-5"
        data-sal="fade"
        data-sal-delay={100}
        data-sal-duration={2000}
      >
        <div className="container d-flex align-items-center justify-content-center flex-column p-2">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-12">
              <h3 className="text-white text-center py-5">
                Voting closes Sunday 3rd April 12pm BST. <br />
                Results Out On The 3rd April 11am-1.30pm BST.
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-10 offset-sm-1 d-flex align-items-center justify-content-center flex-column text-center text-white">
              <h6 className="text-white pb-3">SPONSORED BY</h6>
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-4 col-sm-2 p-2">
                  <a
                    href="https://www.instagram.com/binibini.uk/"
                    target="_blank"
                  >
                    <img src={ImgBiniBini} className="w-100" />
                  </a>
                </div>
                <div className="col-4 col-sm-2 p-2">
                  <a
                    href="https://www.instagram.com/rapsalondon/"
                    target="_blank"
                  >
                    <img src={ImgRapsa} className="w-100" />
                  </a>
                </div>
                <div className="col-4 col-sm-2 p-2">
                  <a
                    href="https://www.instagram.com/k_a_p_i_h_a_n/"
                    target="_blank"
                  >
                    <img src={ImgKhouse} className="w-100" />
                  </a>
                </div>
              </div>
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-4 col-sm-2 p-2">
                  <a
                    href="https://www.instagram.com/kasaandkin/"
                    target="_blank"
                  >
                    <img src={ImgKKOrange} className="w-100" />
                  </a>
                </div>
                <div className="col-4 col-sm-2 p-2">
                  <a
                    href="https://www.instagram.com/sarap_london/"
                    target="_blank"
                  >
                    <img src={ImgSarap} className="w-100" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {window.location.pathname == "/" ? (
            ""
          ) : (
            <div className="row mt-5">
              <Link
                to="/"
                className="pt-3 mt-5 btn btn-light"
                style={{ "text-decoration": "none" }}
              >
                <h3>Back to awards page</h3>
              </Link>
            </div>
          )}
        </div>
      </section>
    </section>
  );
};

export default AwardsFooter;
