import "./App.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

import FiliFestAwards from "./pages/FiliFestAwards";
import Category from "./pages/Category";

import ScrollToTop from "./helpers/ScrollToTop";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar></Navbar>
        <ScrollToTop />

        <Routes>
          <Route
            path="/best-event"
            element={<Category id="best-event" title="Best Event" />}
          ></Route>

          <Route
            path="/peoples-choice-popular-filsoc"
            element={
              <Category
                id="peoples-choice-popular-filsoc"
                title="Peoples Choice Popular FilSoc"
              />
            }
          ></Route>

          <Route
            path="/best-filsoc-president"
            element={
              <Category
                id="best-filsoc-president"
                title="Best FilSoc President"
              />
            }
          ></Route>

          <Route
            path="/filifest-community-choice-award"
            element={
              <Category
                id="filifest-community-choice-award"
                title="FiliFest Community Choice Award"
              />
            }
          ></Route>

          <Route
            path="/best-performer-of-the-year"
            element={
              <Category
                id="best-performer-of-the-year"
                title="Best Performer Of The Year"
              />
            }
          ></Route>

          <Route path="/" element={<FiliFestAwards />}></Route>
        </Routes>

        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
