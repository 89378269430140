import React from "react";
import Particles from "react-particles-js";

export const AwardsParticles = () => {
  return (
    <Particles
      params={{
        particles: {
          number: {
            value: 30,
            density: {
              enable: true,
              value_area: 1000,
            },
          },
          line_linked: {
            enable: false,
          },
          move: {
            speed: 0.8,
            out_mode: "out",
            direction: "bottom",
          },
          shape: {
            type: ["circle"],
            image: [
              {
                src: "/halohalo.PNG",
                height: 100,
                width: 100,
              },
            ],
          },
          color: {
            value: "#fddd4c",
          },

          opacity: {
            value: 0.8,
            random: true,
            anim: {
              enable: true,
              speed: 0.4,
              opacity_min: 0,
              sync: false,
            },
          },
          size: {
            value: 7,
            random: false,
            anim: {
              enable: true,
              speed: 2,
              size_min: 4,
              sync: false,
            },
          },
        },
        interactivity: {
          detect_on: "window",
          events: {
            onhover: {
              enable: true,
              mode: "bubble",
            },
            onclick: {
              enable: false,
              mode: "push",
            },
            resize: true,
          },

          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 83.91608391608392,
              size: 5,
              duration: 3,
              opacity: 1,
              speed: 3,
            },
          },
        },

        retina_detect: false,
      }}
      className="particles particles-events"
    />
  );
};
