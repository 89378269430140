import React from "react";
import { useInView } from "react-intersection-observer";
import { AwardsParticles } from "../../components/Particles";

import CategorySelector from "./CategorySelector";

const CategoryHeader = ({ id, title, description, vote_value }) => {
  const [ref, inView] = useInView();

  const headerImg = {
    "best-event": {
      img: require("../../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 1.png"),
    },
    "peoples-choice-popular-filsoc": {
      img: require("../../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 2.png"),
    },
    "filifest-community-choice-award": {
      img: require("../../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 3.png"),
    },
    "best-performer-of-the-year": {
      img: require("../../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 4.png"),
    },
    "best-filsoc-president": {
      img: require("../../assets/images/filsoc-awards/categories/WEBSITE GRAPHICS 5.png"),
    },
  };

  const headerImgSrc = headerImg[id].img;

  return (
    <section className="category--header">
      <AwardsParticles />

      <div className="row text-center">
        <div className="col-12">
          <CategorySelector />

          {vote_value ? (
            <h3 className="category--header__vote">Voted for: {vote_value}</h3>
          ) : (
            ""
          )}
          <img
            src={headerImgSrc}
            className="category--heading"
            style={{
              transition: "opacity 2000ms, transform 2000ms",
              opacity: inView ? 1 : 0,
              transform: `translateY(${inView ? 0 : -100}px)`,
              transition: "all 2s",
            }}
            ref={ref}
          />
        </div>
      </div>
    </section>
  );
};

export default CategoryHeader;
