import React, { useState } from "react";

import CategoryHeader from "../components/category/CategoryHeader";
import AwardsFooter from "../components/AwardsFooter";

import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";

import BestEvent from "../components/category/BestEvent";
import PeoplesChoicePopularFilsoc from "../components/category/PeoplesChoicePopularFilsoc";
import BestFilsocPresident from "../components/category/BestFilsocPresident";
import FiliFestCommunityChoiceAward from "../components/category/FiliFestCommunityChoiceAward";
import BestPerformerOfTheYear from "../components/category/BestPerformerOfTheYear";

const Category = ({ id, title }) => {
  const [recaptcha, setRecaptcha] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  const vote_value = cookies.get(id);

  var category_component;
  if (id === "best-event") {
    category_component = <BestEvent vote={vote_value} />;
  } else if (id === "peoples-choice-popular-filsoc") {
    category_component = <PeoplesChoicePopularFilsoc vote={vote_value} />;
  } else if (id === "best-filsoc-president") {
    category_component = <BestFilsocPresident vote={vote_value} />;
  } else if (id === "filifest-community-choice-award") {
    category_component = <FiliFestCommunityChoiceAward vote={vote_value} />;
  } else if (id === "best-performer-of-the-year") {
    category_component = <BestPerformerOfTheYear vote={vote_value} />;
  }

  const getIPData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    return res.data;
  };

  const recaptchaRef = React.createRef();

  async function handleSubmit(e) {
    e.preventDefault();
    cookies.set(id, e.target.options.value, { path: "/" });
    navigate(`/${id}`);
    window.scrollTo(0, 0);

    const getIp = await getIPData();
    const res = await axios.post(`https://filifest-awards.glitch.me/vote`, {
      category: id,
      vote_value: e.target.options.value,
      datetime: new Date().toLocaleString(),
      geolocation: getIp,
    });

    console.log("Result: ", res);
  }

  return (
    <section className="category">
      <CategoryHeader id={id} title={title} vote_value={cookies.get(id)} />

      <div className="container">
        <section className="vote">
          <form onSubmit={handleSubmit}>
            <fieldset disabled>
              <h3 className="py-5">Nominees:</h3>

              {category_component}

              {cookies.get(id) ? (
                ""
              ) : (
                <div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6Lf_4hofAAAAAJu64JWWTCBHUz3HDakA-o2r8Ha2"
                    className="d-flex justify-content-center"
                    onChange={() => setRecaptcha(!recaptcha)}
                  />
                  <h3>
                    <input
                      type="submit"
                      value="Submit"
                      className="my-5 btn btn-outline-dark btn-xl"
                      disabled={!recaptcha}
                    />
                  </h3>
                </div>
              )}
            </fieldset>
          </form>
        </section>
      </div>

      <AwardsFooter />
    </section>
  );
};

export default Category;
