import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo-main.png";
import { useLocation } from "@reach/router";

import "../assets/styles/global.scss";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import FiliFestCursor from "./FiliFestCursor";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);

const Navbar = () => {
  const [active, setActive] = useState(false);
  const [whiteNav, setWhiteNav] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    if (scrollTop < 300) {
      setWhiteNav(true);
    } else {
      setWhiteNav(false);
    }
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <nav className={active ? "nav-active" : ""}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <link rel="stylesheet" href="https://use.typekit.net/sxz1lom.css" />

        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>

      {/* {isBrowser ? <FiliFestCursor Page={"community"} /> : ""} */}

      <a href="https://www.filifest.co.uk" className={`nav--closed--logo`}>
        <img src={logo} className="logo-nav" alt="" />
      </a>

      <header className={`cd-header ${whiteNav ? "nav-white" : ""}`}>
        <div className="nav-but-wrap">
          <div
            className="menu-icon hover-target"
            onClick={() => setActive(!active)}
          >
            <span className="menu-icon__line menu-icon__line-left"></span>
            <span className="menu-icon__line"></span>
            <span className="menu-icon__line menu-icon__line-right"></span>
          </div>
        </div>
      </header>

      <div className="nav">
        <div className="logo-nav-wrapper">
          <Link
            fade
            to="/"
            className="nav__list-item nav-link"
            onClick={() => setActive(!active)}
          >
            <img src={logo} className="logo-nav" alt="" />
          </Link>
        </div>

        <div className="nav__content">
          <ul className="nav__list">
            <li className="nav__list-item">
              <a
                href="https://www.filifest.co.uk/about"
                fade
                className="nav-link"
                id="nav-about"
                onClick={() => setActive(!active)}
              >
                About
              </a>
            </li>

            <li className="nav__list-item">
              <a
                href="https://www.filifest.co.uk/community"
                fade
                className="nav-link"
                onClick={() => setActive(!active)}
                id="nav-community"
              >
                Community
              </a>
            </li>
            <li className="nav__list-item">
              <a
                href="https://www.filifest.co.uk/blog"
                fade
                className="nav-link"
                onClick={() => setActive(!active)}
                id="nav-blog"
              >
                Blog
              </a>
            </li>
            <li className="nav__list-item">
              <a
                href="https://www.filifest.co.uk/shop"
                fade
                className="nav-link"
                onClick={() => setActive(!active)}
                id="nav-shop"
              >
                Shop
              </a>
            </li>
            <li className="nav__list-item">
              <a
                href="https://www.filifest.co.uk/events"
                fade
                className="nav-link"
                onClick={() => setActive(!active)}
                id="nav-events"
              >
                Events
              </a>
            </li>
            <li className="nav__list-item">
              <a
                href="https://www.filifest.co.uk/contact"
                fade
                className="nav-link"
                onClick={() => setActive(!active)}
                id="nav-contact"
              >
                Contact
              </a>
            </li>
            <li className="nav__list-item">
              <Link
                to="/"
                fade
                className="nav-link"
                onClick={() => setActive(!active)}
                id="nav-awards"
              >
                Filfest Awards
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
