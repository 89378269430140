import React, { useState } from "react";

const FiliFestCommunityChoiceAward = (vote) => {
  const options = [
    {
      name: "Alexis",
      img: require("../../assets/images/filsoc-awards/categories/Community Choice/Alexis.png"),
      description:
        "Our TFC Choice Winner voted by you, our FiliFest Community, during our Kumu livestreams last November. Give her semi-final performance a watch!",
      watch: "https://www.instagram.com/p/CWacjJaIi-H/",
    },
    {
      name: "Josh Bonifacio",
      img: require("../../assets/images/filsoc-awards/categories/Community Choice/Josh Bonifacio.jpg"),
      description:
        "Nominated by our FiliFest Committee for his efforts and dedication for our Community, particularly his participation for the upcoming Festival.",
    },
    {
      name: "JV Pineda",
      img: require("../../assets/images/filsoc-awards/categories/Community Choice/JV Pineda.jpg"),
      description:
        "Nominated by our FiliFest Committee as one of the MVPs during our Freshers with FiliFest: Live FilSoc Games Night",
      watch: "https://youtu.be/6BWLMoaD6-k",
    },
    {
      name: "Marc Rocafort",
      img: require("../../assets/images/filsoc-awards/categories/Community Choice/Marc Rocafort.png"),
      description:
        "FiliFest appreciates the vlogs by Marc Rocafort particularly for our first FiliFest Challenge Event.",
      watch: "https://www.youtube.com/watch?v=d6wXD_iSjQ8&t=22s",
    },
    {
      name: "Maria Gerella",
      img: require("../../assets/images/filsoc-awards/categories/Community Choice/Maria Gerella.png"),
      description:
        "Maria Maxene Marcos Gerella - Our FiliFest x TFC OPM Competition 2021 Winner won the hearts of our FiliFest Community last November. Watch her heart-warming winning music video!",
      watch: "https://youtu.be/1pI_8NVetdQ",
    },
    {
      name: "UoG FilSoc",
      img: require("../../assets/images/filsoc-awards/categories/Community Choice/UoG FilSoc.png"),
      description:
        "UoG Filipino Society - FiliFest has enjoyed working with University of Greenwich’s Filipino Society in the last year through our FiliFest Challenge event and to have them join us for our Snippet of FiliFest performance at MCM October 2021 London Comic Con.",
      watch: "https://youtu.be/hejzX2UY0Dw",
    },
  ];
  const [selected, setSelected] = useState(options[0]["name"]);

  return (
    <section className="filifest-community-choice-award">
      <fieldset
        value={selected}
        onChange={(event) => setSelected(event.target.value)}
        className="filter-toolbar"
        role="group"
        aria-label="..."
        disabled={vote.vote ? true : false}
      >
        <div className="row">
          {options.map((item, i) => (
            <div
              className="col-sm-6 col-12 p-4 d-flex align-items-center justify-content-center flex-column"
              key={i}
            >
              <label for={item.name}>
                <img src={item.img} alt={item.name} className="w-100" />

                <h3 className="h5 d-flex align-items-center justify-content-center text-center">
                  <input
                    type="radio"
                    value={item.name}
                    name="options"
                    id={item.name}
                    defaultChecked={i === 0 ? true : false}
                  />

                  <span className="p-5 pb-0">{item.name}</span>
                </h3>
                <p>{item.description}</p>
              </label>
              {item.watch ? (
                <h3 className="h5">
                  <a href={item.watch} target="_blank" className="btn btn-dark">
                    Watch
                  </a>
                </h3>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </fieldset>

      {vote.vote ? (
        <h3 className="py-5">Voted for {vote.vote}</h3>
      ) : (
        <h3 className="py-5">
          Voting For: <br /> {selected}
        </h3>
      )}
    </section>
  );
};

export default FiliFestCommunityChoiceAward;
